// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import FileUpload from './BackendComponents/FileUpload';
import AdvertisementGallery from './BackendComponents/AdvertisementGallery';
import Login from './BackendComponents/Login';
import PrivateRoute from './BackendComponents/PrivateRoute';
import { AuthProvider } from './Context/AuthContext';
import './App.css';
import AdvertisementComponent from './AdverisementComponent';
import Home from './Home';
import AdminLayout from './BackendComponents/AdminLayout';
import ProductDisplay from './ProductDisplay';

function App() {


    return (
        <AuthProvider>
            <Router>
                <div className="min-h-screen bg-white transition duration-500">                    
                    <Routes>
                        {/* Public Route for the main website */}
                        <Route path="/" element={<Home />} />

                        <Route path="/products/:category/:type" element={<ProductDisplay />} />
                        <Route path="/brands/:brand" element={<ProductDisplay />} />

                        {/* Private Admin Routes */}
                        <Route element={<PrivateRoute />}>
                            <Route path="/admin/*" element={<AdminLayout />} />
                        </Route>

                        <Route path="/login" element={<Login />} />
                    </Routes>
                </div>
            </Router>
        </AuthProvider>
    );
}

export default App;
