import React from 'react'

function Services() {
  return (
    <div className='mt-4'>
        <h1 className='text-4xl text-center font-bold text-primary' id='services'>Glow Solutions</h1>
        <div className='grid lg:grid-cols-4 grid-cols-1 gap-4'>
            <div className='p-4 col-span-1'>
                <img className='rounded-lg' src={require('./images/GLOW BAR.png')}/>
                <h1 className='text-primary underline text-lg font-semibold mt-4'>Glow Bar</h1>
                <p className='text-black'>Immerse yourself in our interactive beauty experience. Our tester area invites you to try before you buy, ensuring you find products that enhance your unique beauty and style.</p>
            </div>
            <div className='p-4 col-span-1'>
                <img className='rounded-lg' src={require('./images/24 HOURS PHARMACY.png')}/>
                <h1 className='text-primary underline text-lg font-semibold mt-4'>24 Hours Pharmacy</h1>
                <p className='text-black'>Explore our pharmacy section. From essential vitamins and suplements to over-the-counter medications and wellness products, our knowledgable pharmacist is here to assist you in finding the right solution for your health needs.</p>
            </div>
            <div className='p-4 col-span-1'>
                <img className='rounded-lg' src={require('./images/GLOW QUICK CHECK-UP.png')}/>
                <h1 className='text-primary underline text-lg font-semibold mt-4'>Glow Quick Check-Up</h1>
                <p className='text-black'>Prioritize your health with our medical check-up. Our healthcare providers offer comprehensive assessments and consultations to monitor your well-being and address any concerns. From routine screenings to personalized health advice.</p>
            </div>
            <div className='p-4 col-span-1'>
                <img className='rounded-lg' src={require('./images/HEALTH & BEAUTY CONSULTANT.png')}/>
                <h1 className='text-primary underline text-lg font-semibold mt-4'>Health & Beauty Consultation</h1>
                <p className='text-black'>Receive personalized guidance and expert advice with our health consultation services. Our knowledgeable pharmacist provide tailored recommendations based on your individual needs, offering insights to help you achieve and maintain optimal health and wellness.</p>
            </div>
            
        </div>
    </div>
  )
}

export default Services