// src/components/FileUpload.js
import React, { useState } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from '../firebase';

const FileUpload = () => {
    const [file, setFile] = useState(null);  // store the uploaded file
    const [progress, setProgress] = useState(0);  // progress of upload
    const [fileURL, setFileURL] = useState("");  // uploaded file's URL

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = () => {
        if (!file) return;

        const storageRef = ref(storage, `uploads/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        // Monitor progress of the upload
        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const progressPercent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(progressPercent);
            },
            (error) => {
                console.error("Upload failed:", error);
            },
            () => {
                // Get the download URL on successful upload
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setFileURL(downloadURL);
                });
            }
        );
    };

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-50 p-6">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
                <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">Upload Advertisement</h2>
                
                <input 
                    type="file" 
                    onChange={handleFileChange} 
                    className="block w-full text-sm text-gray-500 
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-full file:border-0
                    file:text-sm file:font-semibold
                    file:bg-blue-50 file:text-blue-700
                    hover:file:bg-blue-100 cursor-pointer"
                />
                
                <button 
                    onClick={handleUpload} 
                    className="mt-6 w-full bg-blue-600 text-white font-semibold py-2 px-4 rounded-md hover:bg-blue-700 transition duration-200"
                >
                    Upload
                </button>

                {progress > 0 && (
                    <div className="mt-4">
                        <div className="relative pt-1">
                            <div className="flex mb-2 items-center justify-between">
                                <div>
                                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
                                        Upload Progress
                                    </span>
                                </div>
                                <div className="text-right">
                                    <span className="text-xs font-semibold inline-block text-blue-600">
                                        {Math.round(progress)}%
                                    </span>
                                </div>
                            </div>
                            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
                                <div 
                                    style={{ width: `${progress}%` }} 
                                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-600"
                                ></div>
                            </div>
                        </div>
                    </div>
                )}

                {fileURL && (
                    <div className="mt-6 text-center">
                        <p className="text-green-600 font-semibold">File uploaded successfully!</p>
                        <a 
                            href={fileURL} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="text-blue-600 underline mt-2 inline-block"
                        >
                            View File
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FileUpload;
