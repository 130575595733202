import React, { useState, useEffect } from 'react';
import {
    ref,
    getStorage,
    listAll,
    getDownloadURL,
    deleteObject,
    uploadBytes,
    updateMetadata,
    getMetadata,
} from 'firebase/storage';
import { storage } from '../firebase';
import AdvertisementComponent from '../AdverisementComponent';

const AdvertisementGallery = () => {
    const [imageUrls, setImageUrls] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchImages = async () => {
            setLoading(true);
            const storageRef = ref(storage, 'uploads');
            try {
                const result = await listAll(storageRef);
                const urls = await Promise.all(
                    result.items.map((itemRef) =>
                        getDownloadURL(itemRef).then(async (url) => {
                            const metadata = await getMetadata(itemRef);
                            const name = metadata.customMetadata ? metadata.customMetadata.newName : itemRef.name;
                            return { url, ref: itemRef, name };
                        })
                    )
                );

                // Sort images by name
                const sortedUrls = urls.sort((a, b) => a.name.localeCompare(b.name));
                setImageUrls(sortedUrls);
            } catch (error) {
                console.error('Error fetching images:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchImages();
    }, []);

    const handleDelete = async (imageRef) => {
        try {
            await deleteObject(imageRef);
            setImageUrls((prev) => prev.filter((img) => img.ref !== imageRef));
            console.log('Image deleted successfully');
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    const moveUp = async (index) => {
        if (index === 0) return; // Prevent moving up if it's the first image
        await updateOrder(index, index - 1); // Update the order in Firebase
    };

    const moveDown = async (index) => {
        if (index === imageUrls.length - 1) return; // Prevent moving down if it's the last image
        await updateOrder(index, index + 1); // Update the order in Firebase
    };

    const updateOrder = async (currentIndex, newIndex) => {
        const updatedImages = [...imageUrls];
        const [movedImage] = updatedImages.splice(currentIndex, 1); // Remove the image from its current position
        updatedImages.splice(newIndex, 0, movedImage); // Insert it at the new position
        setImageUrls(updatedImages);

        // Update names in Firebase Storage
        await updateImageNames(updatedImages);
    };

    const updateImageNames = async (images) => {
        const promises = images.map(async (img, index) => {
            const newMetadata = {
                cacheControl: 'public,max-age=300',
                contentType: 'image/jpeg',
                customMetadata: { newName: `image_${index + 1}.jpg` }, // Store new names in metadata
            };

            try {
                await updateMetadata(img.ref, newMetadata);
                console.log(`Updated metadata for ${img.name} to new name: image_${index + 1}.jpg`);
            } catch (error) {
                console.error(`Failed to update metadata for ${img.name}:`, error);
            }
        });

        await Promise.all(promises);
    };

    if (loading) {
        return <p>Loading images...</p>;
    }

    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            <h2 className="text-3xl font-semibold text-center mb-8">Advertisement Gallery</h2>

            <div className="flex flex-col space-y-4 mt-8">
                {imageUrls.length > 0 ? (
                    imageUrls.map((img, index) => (
                        <div key={index} className="relative w-full h-[500px] overflow-hidden rounded-lg shadow-lg bg-white">
                            <img
                                src={img.url}
                                alt={`Uploaded ${img.name}`}
                                className="w-full h-full object-cover transition-opacity duration-500 ease-in-out opacity-0"
                                onLoad={(e) => (e.target.style.opacity = 1)} // Fade in effect when loaded
                                loading="lazy" // Lazy loading
                            />

                            <div className="absolute top-2 left-2 bg-gray-800 text-white rounded-full px-2 py-1">
                                #{index + 1}
                            </div>

                            <button
                                onClick={() => moveUp(index)}
                                className={`absolute top-2 left-12 bg-blue-600 text-white rounded-full px-2 py-1 hover:bg-blue-700 ${
                                    index === 0 ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                                disabled={index === 0}
                            >
                                Move Up
                            </button>

                            <button
                                onClick={() => moveDown(index)}
                                className={`absolute top-2 right-2 bg-green-500 text-white rounded-full px-2 py-1 hover:bg-green-700 ${
                                    index === imageUrls.length - 1 ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                                disabled={index === imageUrls.length - 1}
                            >
                                Move Down
                            </button>

                            <button
                                onClick={() => handleDelete(img.ref)}
                                className="absolute bottom-2 right-2 bg-red-500 text-white rounded-full px-2 py-1 hover:bg-red-700"
                            >
                                Delete
                            </button>
                        </div>
                    ))
                ) : (
                    <p className="text-center col-span-full">No images uploaded yet.</p>
                )}
            </div>
        </div>
    );
};

export default AdvertisementGallery;
