
import { faCamera, faPhone, faShop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useState} from 'react'

function Contact() {
    
    // const [body, setBody] = useState("");
    // const [name, setName] = useState("");
    // const [phoneNumber, setPhoneNumber] = useState("");


//     const handleEmailClick = () => {
//         const email = 'maxwellwijaya00@gmail.com';
//         const subject = 'Contact Form Submission';
//         const emailBody = 'Name: ' + name + '
// ' + 'Phone Number: ' + phoneNumber + '
// ' + 'Message: ' + '
// ' + body 
    
//         const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(emailBody)}`;
    
//         window.location.href = mailtoUrl;
//     };
        
    return (
        
        <div className='w-full bg-white grid lg:grid-cols-2 grid-cols-1 mt-12 lg:px-4' id='contact'>
            <div className='h-full col-span-1'>
                <div className='h-full flex flex-col items-center justify-center'>
                {/* <img src={imageUrl} className='rounded-md w-full'></img> */}
                <iframe src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d126907.60554994701!2d106.5546637527219!3d-6.281777655388321!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x2e69fb86ef285f2b%3A0x2372a05b061d99f8!2sJl.%20BSD%20Raya%20Utama%2C%20Lengkong%20Kulon%2C%20Kec.%20Pagedangan%2C%20Kabupaten%20Tangerang%2C%20Banten%2015331%2C%20Indonesia!3m2!1d-6.281784099999999!2d106.6370657!5e0!3m2!1sen!2sus!4v1728753100954!5m2!1sen!2sus" width={'100%'} height={450} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className='rounded-xl'></iframe>             
                </div>
            
            </div>
            <div>
                <div className="lg:h-full h-60 border text-black rounded-xl shadow-md flex flex-col items-center justify-center px-4 gap-3 col-span-1">
                    <div className='text-3xl font-semibold font-playfair-display text-center'>Contact Us</div>
                        <div className='text-center'>Ararasa Mall, Jl. BSD Raya Utama, Lengkong Kulon, Kec. Pagedangan, Kab. Tangerang, Banten 15331</div>

                        <a className='hover:scale-110 transform transition-transform duration-200 ease-in-out bg-primary text-white px-4 py-2 rounded-full text-center w-full' href='https://wa.me/+6281324354180' target='_blank'><FontAwesomeIcon icon={faPhone}/> WHATSAPP</a>
                        <a className='hover:scale-110 transform transition-transform duration-200 ease-in-out bg-primary text-white px-4 py-2 rounded-full text-center w-full' href='https://www.instagram.com/glow24.id?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==' target='_blank'><FontAwesomeIcon icon={faCamera}/> INSTAGRAM</a>
                    
                </div>
            </div>
            
            
        </div>
        
    )
}

export default Contact
