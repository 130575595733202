import React from 'react'

function About() {
  return (
    <div className="grid lg:grid-cols-5 grid-cols-1" id='about'>
        <div className='col-span-2 hidden lg:block'>
            <img className='h-full rounded-[40px]' src={require('./images/heroImg.png')}></img> 
        </div>
        <div className='col-span-3 bg-primary grid grid-rows-6 rounded-l-[40px]'>
            <div className='row-span-2 bg-background rounded-l-[40px] flex items-center justify-center'>
                <img height={150} width={250} src={require('./images/glowLogo2.png')}></img>
            </div>
            <div className='row-span-4 rounded-l-[40px] p-8'>
                <h1 className='text-white text-4xl font-bold mb-4'>About Us</h1>
                <p className='text-white'>
                    GLOW+24 is a leading health and beauty retail pharmacy that offer a wide range of products related to personal care, wellness, and medical needs including medications, skincare, cosmetics, vitamins, supplements, etc.
                    These stores aim to provide a convenient one-stop-shop for customers’ health and beauty needs.
                    GLOW+24 will be the first 24 Hours Health & Beauty Retail Pharmacy in a
                    Mall.

                </p>

                <div className='grid lg:grid-cols-2 grid-cols-1 text-white bg-secondary p-4 gap-2 rounded-xl mt-4'>
                    <div>
                        <h1 className='text-xl font-semibold'>Vision</h1>
                        <ul className='p-2'>
                            <li>To be the trusted partner in health & beauty, empowering individuals to live their lives through personalized care, expert advice, and innovative products</li>
                            <br></br>
                            <li>To be the ultimate destination where health meets beauty, offering unparalleled product diversity and customer-centric services that enrich lives and foster well-being</li>
                        </ul>
                       
                    </div>
                    <div>
                        <h1 className='text-xl font-semibold'>Mission</h1>
                        <ul className='p-2'>
                            <li>To be the first 24 hours Health & Beauty Store in a Mall</li>
                            <br></br>
                            <li>Health & Beauty Store with a wide variety of health and beauty products</li>
                            <br></br>
                            <li>To empowered individuals to live beautifully inside and out by providing expert guidance, innovative products, and exceptional experiences that inspire confidence happiness, and vitality</li>
                        </ul>
                       
                    </div>
                </div>

            </div>
        </div>
        
    </div>
  )
}

export default About