import { faShop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

function Hero({ heading, description, imageUrl }) {

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <section className="flex flex-col lg:flex-row items-center justify-between h-auto bg-white px-6 lg:px-12 py-12" id="hero">
            {/* Left Section: Text Content */}
            <div className="lg:w-1/2 w-full flex flex-col justify-center text-center lg:text-left p-6 lg:p-12 space-y-6">
                <h1 className="text-gray-900 font-bold text-4xl lg:text-5xl xl:text-6xl">
                    {heading}
                </h1>
                <p className="text-gray-700 text-lg lg:text-xl">
                    {description}
                </p>
                <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0 justify-center lg:justify-start">
                    <button
                        onClick={() => scrollToSection('services')}
                        className="px-8 py-3 bg-primary text-white rounded-full shadow-lg transition duration-300 ease-in-out"
                    >
                        Check our Glow Solutions
                    </button>
                    <Link to={`/products/all/all`}>
                        <button className="px-8 py-3 bg-gray-900 w-full text-white rounded-full shadow-lg hover:bg-gray-800 transition duration-300 ease-in-out">
                            <FontAwesomeIcon icon={faShop} />  View Products
                        </button>
                    </Link>
                </div>
            </div>

            {/* Right Section: Image - Rounded Edges */}
            <div className="lg:w-1/2 w-full h-1/2 lg:h-full mt-8 lg:mt-0 flex items-center justify-center relative">
                <img
                    src={imageUrl}
                    alt="Hero"
                    className="w-full h-auto rounded-3xl shadow-lg object-cover"
                />
            </div>
        </section>
    );
}

export default Hero;
