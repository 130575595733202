import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import FileUpload from './FileUpload';
import AdvertisementComponent from '../AdverisementComponent';
import AdvertisementGallery from './AdvertisementGallery';

function AdminLayout() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State for sidebar visibility

    const toggleSidebar = () => {
        setIsSidebarOpen((prev) => !prev); // Toggle sidebar open/close
    };

    return (
            <div className="flex min-h-screen">
                {/* Hamburger Icon for Small Screens */}
                <button
                    onClick={toggleSidebar}
                    className="md:hidden p-2 text-white bg-gray-800 rounded fixed top-4 left-4 z-20"
                    aria-label="Toggle Sidebar"
                >
                    <FontAwesomeIcon icon={faBars} size="lg" />
                </button>

                {/* Sidebar */}
                <nav
                    className={`fixed h-screen bg-gray-800 text-white transition-transform duration-300 ease-in-out 
                    ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} 
                    md:translate-x-0 md:relative md:w-64 z-10`}
                >
                    <div className="p-4">
                        <h2 className="text-2xl font-bold mb-4 lg:mt-0 mt-16">Admin Dashboard</h2>
                        <ul className="space-y-4">
                            <li>
                                <Link to="/admin/upload" className="hover:underline">Upload</Link>
                            </li>
                            <li>
                                <Link to="/admin/gallery" className="hover:underline">Gallery</Link>
                            </li>
                            <li>
                                <Link to="/admin/preview" className="hover:underline">Preview</Link>
                            </li>
                        </ul>
                    </div>
                </nav>

                {/* Main content area */}
                <div className={`flex-grow bg-gray-100 p-6 transition-margin duration-300 ml-0`}>
                    <Routes>
                        <Route path="upload" element={<FileUpload />} />
                        <Route path="gallery" element={<AdvertisementGallery />} />
                        <Route path="preview" element={<Preview />} />
                    </Routes>
                </div>
            </div>
    );
}

function Preview() {
    return (
        <div className="p-6">
            <h1 className="text-3xl text-center font-semibold">Preview Advertisement</h1>
            <AdvertisementComponent />
        </div>
    );
}

export default AdminLayout;
