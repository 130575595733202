import React from 'react'
import Navbar from './Navbar'
import Hero_10 from './Hero'
import Hero from './Hero'
import AdvertisementComponent from './AdverisementComponent'
import About from './About'
import Events from './Events'
import Services from './Services'
import Products from './Products'
import Faqs from './Faqs'
import Footer from './Footer'
import Contact from './Contact'

function Home() {
  return (
    <div className='bg-white'>
        <Navbar />
        <AdvertisementComponent />
        <Hero heading={"Discover Your Perfect Health & Beauty Essentials"} description={"Experience a curated collection of top products for a healthier, more radiant you — all in one place."} imageUrl={require("./images/glow plus hero.jpg")}/>
        <About />
        {/* <Events /> */}
        <Services />
        {/* <Products /> */}
        {/* <Faqs /> */}
        <Contact />
        <Footer />
    </div>
  )
}

export default Home