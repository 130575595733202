import React, { useState } from "react";
import ImageComponent from "./ImageComponent"; // Assuming you have this component

const ProductGrid = ({ products, categoryFilter = "all", brandFilter = "all", typeFilter = "all" }) => {

  console.log(categoryFilter, brandFilter, typeFilter);
  // Filter products based on the selected category and brand
  const filteredProducts = products.filter((product) => {
    const categoryMatch = product.Category.toLowerCase() === categoryFilter.toLowerCase() || categoryFilter === "all";
    const brandMatch = product.Brand.toLowerCase() === brandFilter.toLowerCase() || brandFilter === "all";
    const typeMatch = product.Type?.toLowerCase() === typeFilter.toLowerCase() || typeFilter === "all";
  
    return categoryMatch && brandMatch && typeMatch;
  });
  

  console.log(filteredProducts);

  return (
    <div className="container mx-auto">
      {/* Product Grid */}
      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-5 gap-4">
        {filteredProducts.map((item) => (
          <div
            key={item.ID}
            className="max-w-sm bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300"
          >
            <div className="h-64 w-full overflow-hidden">
              <ImageComponent imageUrl={item.Image} alt={item.Title} className="w-full h-full object-cover" />
            </div>
            <div className="p-4">
              <h2 className="text-lg font-semibold text-gray-800">{item.Title}</h2>
              <p className="text-gray-600 mt-2">{item.Description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductGrid;
