import React, { useState } from 'react'

function ImageComponent({imageUrl}) {
    if (!imageUrl || typeof imageUrl !== 'string') {
        console.error("Invalid or undefined image URL: ", imageUrl);
        return <div>Image not available</div>;
    }
    

    return (
        <div>
            <img
                src={imageUrl}
                alt="Product Image"
                style={{ maxWidth: '100%', height: 'auto' }}
                onError={(e) => e.target.src = 'https://via.placeholder.com/150'}            
            />
        </div>
    );
}

export default ImageComponent
  