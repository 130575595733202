import React, { useState, useEffect } from 'react';
import { listAll, ref, getDownloadURL, getMetadata } from 'firebase/storage';
import { storage } from './firebase'; // Ensure you import your Firebase configuration
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const AdvertisementComponent = () => {
    const [imageData, setImageData] = useState([]); // Array to hold image URLs and names
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const storageRef = ref(storage, 'uploads/');
                const result = await listAll(storageRef);

                // Fetch URLs and metadata
                const urlPromises = result.items.map(async (imageRef) => {
                    try {
                        const url = await getDownloadURL(imageRef);
                        const metadata = await getMetadata(imageRef); // Fetch metadata
                        const name = metadata.customMetadata ? metadata.customMetadata.newName : ''; // Access newName
                        return { url, name }; // Return an object with URL and name
                    } catch (error) {
                        console.error('Error loading image:', error);
                        return { url: 'placeholder.jpg', name: 'Error loading' }; // Fallback to placeholder on error
                    }
                });

                const urlsWithNames = await Promise.all(urlPromises);

                // Sort images based on the newName metadata
                urlsWithNames.sort((a, b) => {
                    const numA = parseInt(a.name.split('_')[1], 10); // Extract number from newName like image_2.jpg
                    const numB = parseInt(b.name.split('_')[1], 10);
                    return numA - numB; // Sort in ascending order
                });

                setImageData(urlsWithNames);
            } catch (error) {
                console.error('Error fetching images:', error); // Log any errors
            }
        };

        fetchImages();
    }, []);

    useEffect(() => {
        if (imageData.length > 0) {
            lazyLoadImages(currentIndex); // Call lazy load only if images are present
        }
    }, [currentIndex, imageData.length]);

    // Lazy load the next or previous images based on the current index
    const lazyLoadImages = (currentIndex) => {
        const nextIndex = (currentIndex + 1) % imageData.length;
        const prevIndex = (currentIndex - 1 + imageData.length) % imageData.length;

        // No need to explicitly track loaded images now; we'll just prefetch the current, next, and previous ones
        const prefetchImages = [currentIndex, nextIndex, prevIndex];

        prefetchImages.forEach(index => {
            const img = new Image();
            img.src = imageData[index]?.url; // Prefetch images by setting the src
        });
    };

    const handlePreviousSlide = () => {
        setIsTransitioning(true);
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? imageData.length - 1 : prevIndex - 1));
        setTimeout(() => setIsTransitioning(false), 500); // Adjust timing based on transition duration
    };

    const handleNextSlide = () => {
        setIsTransitioning(true);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % imageData.length);
        setTimeout(() => setIsTransitioning(false), 500); // Adjust timing based on transition duration
    };

    return (
        <div className="w-full mt-[70px] sm:h-300px md:h-[400px] lg:h-[400px] relative overflow-hidden rounded-b-lg">
            {imageData.length > 0 ? (
                <>
                    <div
                        className={`w-full h-full flex transition-transform duration-700 ease-in-out transform ${isTransitioning ? 'transition-transform' : ''}`}
                        style={{
                            transform: `translateX(-${currentIndex * 100}%)`,
                        }}
                    >
                        {imageData.map(({ url, name }, index) => (
                            <img
                                key={index}
                                src={url} // No lazy loading logic needed for simplicity
                                alt={`preview-${name}`} // Use the custom name for alt text
                                className="w-full h-full lg:object-cover object-fill"
                                style={{ minWidth: '100%' }} // Ensures each image takes full screen width
                            />
                        ))}
                    </div>

                    {/* Left Arrow */}
                    <button
                        onClick={handlePreviousSlide}
                        className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-gray-700 bg-opacity-50 hover:bg-opacity-100 text-white p-3 rounded-full z-10"
                    >
                        <FontAwesomeIcon icon={faChevronLeft} size="2x" />
                    </button>

                    {/* Right Arrow */}
                    <button
                        onClick={handleNextSlide}
                        className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-gray-700 bg-opacity-50 hover:bg-opacity-100 text-white p-3 rounded-full z-10"
                    >
                        <FontAwesomeIcon icon={faChevronRight} size="2x" />
                    </button>

                    {/* Carousel navigation */}
                    <div className="absolute bottom-4 flex justify-center w-full gap-2 z-10">
                        {imageData.map((_, index) => (
                            <button
                                key={index}
                                onClick={() => setCurrentIndex(index)}
                                className={`btn btn-xs ${currentIndex === index ? 'bg-white' : 'bg-gray-400'}`}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </>
            ) : (
                <p className="text-white">No images available to display.</p>
            )}
        </div>
    );
};

export default AdvertisementComponent;
